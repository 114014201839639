/* istanbul ignore file */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { cloneModel, createModel, deleteModel, getModel, listModels } from 'services/deepRacerLite';
import { displayErrorNotification, displaySuccessNotification } from 'store/notifications';
import { useAppDispatch } from 'store/utils/hooks';
import i18n from 'i18n';

enum ModelQueryKeysEnum {
  Model = 'Model',
  Models = 'Models',
}

const useGetModel = (modelArn: string) => {
  const dispatch = useAppDispatch();
  return useQuery([ModelQueryKeysEnum.Model, modelArn], () => getModel(modelArn), {
    enabled: !!modelArn && modelArn !== 'undefined',
    onError: (error: any) => {
      dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    },
  });
};

const useListModels = () => {
  const dispatch = useAppDispatch();
  return useQuery([ModelQueryKeysEnum.Models], () => listModels(), {
    onError: (error: any) => {
      dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    },
  });
};

const useDeleteReinforcementModel = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  return useMutation((modelArn: string) => deleteModel(modelArn), {
    onSuccess: () => {
      dispatch(displaySuccessNotification({ content: i18n.t('APIsSuccessMessages.deleteModel') }));
      queryClient.invalidateQueries([ModelQueryKeysEnum.Models]);
    },
    onError: (error: any) => {
      dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    },
  });
};

const useCreateReinforcementModel = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  return useMutation(
    ({
      customActionSpace,
      description,
      modelName,
      tagList,
      trainingConfig,
      agentAlgorithm,
      competitionCountryCode,
    }: any) =>
      createModel(
        customActionSpace,
        description,
        modelName,
        tagList,
        trainingConfig,
        agentAlgorithm,
        competitionCountryCode
      ),
    {
      onSuccess: () => {
        dispatch(displaySuccessNotification({ content: i18n.t('APIsSuccessMessages.createModel') }));
        queryClient.invalidateQueries([ModelQueryKeysEnum.Models]);
      },
      onError: (error: any) => {
        dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
      },
    }
  );
};

const useCloneReinforcementModel = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  return useMutation(
    ({
      customActionSpace,
      description,
      modelName,
      tagList,
      trainingConfig,
      modelArnToClone,
      agentAlgorithm,
      competitionCountryCode,
    }: any) =>
      cloneModel(
        customActionSpace,
        description,
        modelName,
        tagList,
        trainingConfig,
        modelArnToClone,
        agentAlgorithm,
        competitionCountryCode
      ),
    {
      onSuccess: () => {
        dispatch(displaySuccessNotification({ content: i18n.t('APIsSuccessMessages.cloneModel') }));
        queryClient.invalidateQueries([ModelQueryKeysEnum.Models]);
      },
      onError: (error: any) => {
        dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
      },
    }
  );
};

export {
  ModelQueryKeysEnum,
  useGetModel,
  useListModels,
  useDeleteReinforcementModel,
  useCreateReinforcementModel,
  useCloneReinforcementModel,
};
