import { AsyncResource, AsyncResourceList, AsyncResourceMap } from 'store/types';

export const SUCCESS = 'SUCCESS';
export const FULFILLED = 'fulfilled';
export const PENDING = 'pending';
export const REJECTED = 'rejected';

// Slice constants
export const AUTH_SLICE = 'auth';
export const NOTIFICATIONS_SLICE = 'notifications';
export const HELPPANEL_SLICE = 'helppanel';
export const MODEL_SLICE = 'model';
export const TRACK_SLICE = 'track';
export const TRAINING_JOB_SLICE = 'trainingJob';
export const PROFILE_SLICE = 'profile';
export const LEADERBOARD_SLICE = 'leaderboard';
export const LEADERBOARD_SUBMISSION_SLICE = 'leaderboardSubmission';
export const ROUTER_SLICE = 'router';
export const LMS_TRACKER_SLICE = 'lmsTracker';
export const LMS_QUIZ_SLICE = 'lmsQuiz';
export const CONFETTI_SLICE = 'confetti';
export const DARK_MODE_SLICE = 'darkMode';

// Each action name should start with corresponding slice name, otherwise store will not be updated

export enum AuthAction {
  CHANGE_PASSWORD = 'auth/changePassword',
  CHECK_AUTH_STATUS = 'auth/checkAuthStatus',
  CONFIRM_REGISTRATION = 'auth/confirmRegistration',
  DELETE_USER = 'auth/deleteUser',
  FORGOT_PASSWORD_REQUEST = 'auth/forgotPasswordRequest',
  HANDLE_FORGOT_PASSWORD_REQUEST = 'auth/handleForgotPasswordRequest',
  HANDLE_RESEND_CODE = 'auth/handleResendCode',
  FORGOT_PASSWORD_RESET = 'auth/forgotPasswordReset',
  INIT_REGISTRATION = 'auth/initiateRegistration',
  LOGIN = 'auth/login',
  LOGOUT = 'auth/logout',
  RESEND_REGISTRATION_CODE = 'auth/resendRegistrationCode',
  SET_REGISTER_ACCOUNT_EMAIL = 'auth/setRegisterAccountEmail',
  SIGN_IN = 'auth/signIn',
  SIGN_IN_ERROR = 'auth/signInError',
  SIGN_UP = 'auth/signUp',
  SIGN_UP_ERROR = 'auth/signUpError',
  VERIFY_EMAIL = 'auth/verifyEmail',
}

export enum AuthException {
  EMAIL_NOT_VERIFIED = 'EmailNotVerified',
  NOT_AUTHORIZED = 'NotAuthorized',
  THROTTLING_EXCEPTION = 'TooManyRequestsException',
  TRANSIENT_ERROR = 'TransientError',
  USERNAME_EXISTS = 'UsernameExistsException',
  DOMAIN_NOT_ALLOWED = 'DomainNotAllowed',
}

export enum NotificationsAction {
  CLEAR_ALL_NOTIFICATION = 'notifications/clearAllNotifications',
  DISPLAY_ERROR_NOTIFICATION = 'notifications/displayErrorNotification',
  DISPLAY_NOTIFICATION = 'notifications/displayNotification',
  DISPLAY_SUCCESS_NOTIFICATION = 'notifications/displaySuccessNotification',
  DISPLAY_WARNING_NOTIFICATION = 'notifications/displayWarningNotification',
  REMOVE_NOTIFICATION = 'notifications/removeNotification',
}

export enum ModelAction {
  VALIDATE_MODEL = 'model/validateModel',
  CLEAR_VALIDATE_MODEL = 'model/clearValidateModel',
}

export enum LmsQuizAction {
  GET_ASSESSMENT_TRACKERS = 'lmsQuiz/getAssessmentTrackers',
  GET_ASSESSMENT_TRACKER = 'lmsQuiz/getAssessmentTracker',
  CREATE_ASSESSMENT_TRACKER = 'lmsQuiz/createAssessmentTracker',
  START_ASSESSMENT_TRACKER = 'lmsQuiz/startAssessmentTracker',
  FINISH_ASSESSMENT_TRACKER = 'lmsQuiz/finishAssessmentTracker',
  GET_CURRENT_QUESTION = 'lmsQuiz/getCurrentQuestion',
  GET_NEXT_QUESTION = 'lmsQuiz/getNextQuestion',
  GET_PREV_QUESTION = 'lmsQuiz/getPrevQuestion',
  POST_ANSWER = 'lmsQuiz/postAnswer',
  CLEAR_ASSESSMENT_TRACKERS = 'lmsQuiz/clearAssessmentTrackers',
  CLEAR_ASSESSMENT_TRACKER = 'lmsQuiz/clearAssessmentTracker',
  CLEAR_CREATE_ASSESSMENT_TRACKER = 'lmsQuiz/clearCreateAssessmentTracker',
  CLEAR_START_ASSESSMENT_TRACKER = 'lmsQuiz/clearStartAssessmentTracker',
  CLEAR_FINISH_ASSESSMENT_TRACKER = 'lmsQuiz/clearFinishAssessmentTracker',
  CLEAR_QUESTION = 'lmsQuiz/clearQuestion',
  CLEAR_POST_ANSWER = 'lmsQuiz/clearPostAnswer',
}

export enum TrainingJobAction {
  GET_TRAINING_JOB = 'trainingJob/getTrainingJob',
  LIST_TRAINING_JOBS = 'trainingJob/listTrainingJobs',
  STOP_TRAINING_JOB = 'trainingJob/stopTrainingJob',
  CLEAR_TRAINING_JOB = 'trainingJob/clearTrainingJob',
  CLEAR_TRAINING_JOBS = 'trainingJob/clearTrainingJobs',
  CLEAR_STOP_TRAINING_JOB = 'trainingJob/clearStopTrainingJob',
}

export enum TrackAction {
  LIST_TRACKS = 'track/listTracks',
  GET_TRACK = 'track/getTrack',
  CLEAR_TRACKS = 'track/clearTracks',
  CLEAR_TRACK = 'track/clearTrack',
}

export enum ProfileAction {
  DELETE_PROFILE = 'profile/deleteMyProfile',
  FETCH_PROFILE = 'profile/fetchProfile',
  UPDATE_PROFILE = 'profile/updateProfile',
  HANDLE_UPDATE_PROFIE = 'profile/handleUpdateProfile',
  CHECK_COMPLETE_SIGN_UP = 'profile/checkCompleteSignUp',
}

export enum LeaderboardAction {
  LIST_LEADERBOARD_EVALUATIONS = 'leaderboard/listLeaderboardEvaluations',
  CLEAR_LEADERBOARD_EVALUATIONS = 'leaderboard/clearLeaderboardEvaluations',
}

export enum LeaderboardSubmissionAction {
  GET_LATEST_LEADERBOARD_SUBMISSION = 'leaderboardSubmission/getLatestLeaderboardSubmission',
  CLEAR_LATEST_LEADERBOARD_SUBMISSION = 'leaderboardSubmission/clearLatestUserSubmission',
}

export enum LmsTrackerAction {
  ADD_LMS_TRACKER_MODULE = 'lmsTracker/addModule',
  GET_LMS_TRACKER_MODULES = 'lmsTracker/getModules',
  ADD_LMS_TRACKER_CHAPTER = 'lmsTracker/addChapter',
  GET_LMS_TRACKER_CHAPTERS = 'lmsTracker/getChapters',
  UPDATE_LMS_TRACKER_CHAPTER = 'lmsTracker/updateChapter',
  ADD_LMS_TRACKER_SECTION = 'lmsTracker/addSection',
  GET_LMS_TRACKER_SECTIONS = 'lmsTracker/getSections',
  UPDATE_LMS_TRACKER_SECTION = 'lmsTracker/updateSection',
}

export const emptyResourceList: AsyncResourceList<any> = {
  isLoading: false,
  error: null,
  items: [],
};

export const emptyResourceMap: AsyncResourceMap<any> = {
  isLoading: false,
  error: null,
  items: {},
};

export const emptyResource: AsyncResource<any> = {
  isLoading: false,
  error: null,
  item: null,
};
