import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@amzn/awsui-components-react/polaris/box';
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import { useTranslation } from 'react-i18next';

import DRLogo from 'common/assets/studentLeagueLogoLight.png';
import { useAppDispatch } from 'store/utils/hooks';
import { clearAllNotifications } from 'store/notifications';
import {
  ForgotPasswordRequestForm,
  ForgotPasswordResetForm,
  SignInForm,
  SignUpForm,
  VerifyEmailForm,
} from 'common/components/AuthForms';

import './styles.css';

export enum AuthFormType {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET',
}

interface AuthPageProps {
  formType: AuthFormType;
}

const getForm = (formType: AuthFormType) => {
  switch (formType) {
    case AuthFormType.SIGN_UP:
      return <SignUpForm />;
    case AuthFormType.VERIFY_EMAIL:
      return <VerifyEmailForm />;
    case AuthFormType.FORGOT_PASSWORD_REQUEST:
      return <ForgotPasswordRequestForm />;
    case AuthFormType.FORGOT_PASSWORD_RESET:
      return <ForgotPasswordResetForm />;
    case AuthFormType.SIGN_IN:
    default:
      return <SignInForm />;
  }
};

export const AuthPage = ({ formType }: AuthPageProps) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(clearAllNotifications());
  }, [dispatch, pathname]);

  const showScholarshipAlert = formType === AuthFormType.SIGN_IN || formType === AuthFormType.SIGN_UP;

  return (
    <ContentLayout
      header={
        <Box textAlign="center" padding={{ bottom: showScholarshipAlert ? 'xxl' : 'n' }}>
          <img src={DRLogo} width="180px" height="180px" />
          <Box
            variant="h1"
            margin={{ bottom: showScholarshipAlert ? 'xxl' : 'n' }}
            padding={{ bottom: showScholarshipAlert ? 'xxl' : 'm' }}
          >
            {t('authPageTitle')}
          </Box>
        </Box>
      }
    >
      {getForm(formType)}
    </ContentLayout>
  );
};

export const SignInPage = () => <AuthPage formType={AuthFormType.SIGN_IN} />;
export const SignUpPage = () => <AuthPage formType={AuthFormType.SIGN_UP} />;
export const VerifyEmailPage = () => <AuthPage formType={AuthFormType.VERIFY_EMAIL} />;
export const ForgotPasswordRequestPage = () => <AuthPage formType={AuthFormType.FORGOT_PASSWORD_REQUEST} />;
export const ForgotPasswordResetPage = () => <AuthPage formType={AuthFormType.FORGOT_PASSWORD_RESET} />;
