import { MAX_RESULTS } from 'common/constants';
import { listAllItems } from 'services/deepRacerLite/common';
import ServiceProvider from 'services/ServiceProvider';
import { getIdToken } from 'store/utils/authUtils';
import { DeepRacerLiteService, DeepRacerLiteTypes } from 'types/DeepRacerLiteTypes';

/**
 * Get the lists all the models
 * @param
 * @returns NextToken, ModelList
 */
export const listModels = async (): Promise<DeepRacerLiteTypes.ListModelsResponse> => {
  try {
    const listModelsRequest: DeepRacerLiteTypes.ListModelsRequest = {
      MaxResults: MAX_RESULTS,
      ModelType: DeepRacerLiteTypes.ModelType.REINFORCEMENT_LEARNING,
      SortBy: DeepRacerLiteTypes.SortModelsBy.CREATION_TIME,
      SortOrder: DeepRacerLiteTypes.ListSortOrder.DESCENDING,
    };
    const listModelsResponse: DeepRacerLiteTypes.ListModelsResponse = {
      Models: [],
    };
    return listAllItems('listModels', listModelsRequest, listModelsResponse, 'Models');
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Get the model by arn
 * @param ModelArn
 * @returns Model
 */
export const getModel = async (modelArn: string): Promise<DeepRacerLiteTypes.GetModelResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const getModelRequest: DeepRacerLiteTypes.GetModelRequest = {
      ModelArn: modelArn,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.getModel(getModelRequest, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Create new model
 * @param CreateReinforcementLearningModelRequest
 * @returns ModelArn
 */
export const createModel = async (
  customActionSpace: DeepRacerLiteTypes.CustomActionSpace,
  description: string,
  modelName: string,
  tagList: DeepRacerLiteTypes.TagList,
  trainingConfig: DeepRacerLiteTypes.TrainingConfig,
  agentAlgorithm: DeepRacerLiteTypes.AgentAlgorithm,
  competitionCountryCode: string
): Promise<DeepRacerLiteTypes.CreateReinforcementLearningModelResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const createReinforcementLearningModelRequest: DeepRacerLiteTypes.CreateReinforcementLearningModelRequest = {
      CustomActionSpace: customActionSpace,
      ModelDescription: description,
      ModelName: modelName,
      Tags: tagList,
      TrainingConfig: trainingConfig,
      AgentAlgorithm: agentAlgorithm,
      CompetitionCountryCode: competitionCountryCode as DeepRacerLiteTypes.CountryCode,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.createReinforcementLearningModel(
      createReinforcementLearningModelRequest,
      idToken
    );
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Clone model from existing model
 * @param CloneReinforcementLearningModelRequest
 * @returns ModelArn
 */
export const cloneModel = async (
  customActionSpace: DeepRacerLiteTypes.CustomActionSpace,
  description: string,
  modelName: string,
  tagList: DeepRacerLiteTypes.TagList,
  trainingConfig: DeepRacerLiteTypes.TrainingConfig,
  modelArnToClone: string,
  agentAlgorithm: DeepRacerLiteTypes.AgentAlgorithm,
  competitionCountryCode: string
): Promise<DeepRacerLiteTypes.CloneReinforcementLearningModelResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const cloneReinforcementLearningModelRequest: DeepRacerLiteTypes.CloneReinforcementLearningModelRequest = {
      CustomActionSpace: customActionSpace,
      ModelDescription: description,
      ModelName: modelName,
      Tags: tagList,
      TrainingConfig: trainingConfig,
      ModelArnToClone: modelArnToClone,
      AgentAlgorithm: agentAlgorithm,
      CompetitionCountryCode: competitionCountryCode as DeepRacerLiteTypes.CountryCode,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.cloneReinforcementLearningModel(cloneReinforcementLearningModelRequest, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Delete the model by arn
 * @param ModelArn
 * @returns
 */
export const deleteModel = async (modelArn: string): Promise<DeepRacerLiteTypes.DeleteModelResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const deleteModelRequest: DeepRacerLiteTypes.DeleteModelRequest = {
      ModelArn: modelArn,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.deleteModel(deleteModelRequest, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Validate the model
 * @param rewardFunction
 * @returns
 */
export const validateModel = async (
  rewardFunction: string,
  trackArn: string,
  trackDirection: DeepRacerLiteTypes.TrackDirection
): Promise<DeepRacerLiteTypes.TestRewardFunctionResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const testRewardFunctionRequest: DeepRacerLiteTypes.TestRewardFunctionRequest = {
      RewardFunctionCode: rewardFunction,
      TrackArn: trackArn,
      TrackDirection: trackDirection,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.testRewardFunction(testRewardFunctionRequest, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Get an s3 presigned url for the specified asset
 * @param arn the asset arn
 * @param assetType the asset type
 */
export const getAssetUrl = async (
  arn: string,
  assetType = DeepRacerLiteTypes.AssetType.MODEL_ARTIFACT
): Promise<DeepRacerLiteTypes.GetAssetUrlResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const getAssetUrlRequest: DeepRacerLiteTypes.GetAssetUrlRequest = {
      Arn: arn,
      AssetType: assetType,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.getAssetUrl(getAssetUrlRequest, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};
