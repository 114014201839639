import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import notificationsReducer from './notifications';
import {
  AUTH_SLICE,
  NOTIFICATIONS_SLICE,
  HELPPANEL_SLICE,
  MODEL_SLICE,
  TRACK_SLICE,
  TRAINING_JOB_SLICE,
  PROFILE_SLICE,
  LEADERBOARD_SLICE,
  LEADERBOARD_SUBMISSION_SLICE,
  LMS_TRACKER_SLICE,
  LMS_QUIZ_SLICE,
  CONFETTI_SLICE,
  DARK_MODE_SLICE,
} from 'common/constants';
import helppanelReducer from './helppanel/helpPanelSlice';
import modelReducer from './model/modelSlice';
import trackReducer from './track/trackSlice';
import trainingJobReducer from './trainingJob/trainingJobSlice';
import profileReducer from './profile/profileSlice';
import leaderboardReducer from './leaderboard/leaderboardSlice';
import leaderboardSubmissionReducer from './leaderboardSubmission/leaderboardSubmissionSlice';
import lmsTrackerReducer from './lmsTracker/lmsTrackerSlice';
import lmsQuizReducer from './lmsQuiz/lmsQuizSlice';
import confettiReducer from './confetti/confettiSlice';
import darkModeReducer from './darkMode/darkModeSlice';

export const store = configureStore({
  reducer: {
    [AUTH_SLICE]: authReducer,
    [NOTIFICATIONS_SLICE]: notificationsReducer,
    [HELPPANEL_SLICE]: helppanelReducer,
    [PROFILE_SLICE]: profileReducer,
    [MODEL_SLICE]: modelReducer,
    [TRACK_SLICE]: trackReducer,
    [TRAINING_JOB_SLICE]: trainingJobReducer,
    [LEADERBOARD_SLICE]: leaderboardReducer,
    [LEADERBOARD_SUBMISSION_SLICE]: leaderboardSubmissionReducer,
    [LMS_TRACKER_SLICE]: lmsTrackerReducer,
    [LMS_QUIZ_SLICE]: lmsQuizReducer,
    [CONFETTI_SLICE]: confettiReducer,
    [DARK_MODE_SLICE]: darkModeReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch &
  ThunkDispatch<any, null, AnyAction> &
  ThunkDispatch<any, undefined, AnyAction>;
